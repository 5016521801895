<template>
  <div class="caseProjectBoxForm">
    <el-form v-if="caseProjectBoxForm==='case'" ref="form" :model="form" 
    label-width="72px">
      <el-form-item label="案件名称">
        <el-input size='small'
        v-model="form.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="案件编号">
        <el-input size='small' v-model="form.order" clearable></el-input>
      </el-form-item>
      <el-form-item label="当事人">
        <el-input size='small'
         v-model="form.person" clearable></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select size='small'
        v-model="form.type" placeholder="请选择">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select size='small'
        v-model.stop="form.status" placeholder="请选择">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="归属">
        <el-select size='small'
        v-model="form.belong" placeholder="请选择">
          <el-option
            v-for="item in belongOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <el-form v-if="caseProjectBoxForm==='project'" ref="form" 
    :model="form" label-width="72px">
      <el-form-item label="项目名称">
        <el-input size='small'
        v-model="form.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="项目编号">
        <el-input size='small' v-model="form.order" clearable></el-input>
      </el-form-item>
      <el-form-item label="委托方">
        <el-input size='small'
         v-model="form.person" clearable></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select size='small'
        v-model="form.type" placeholder="请选择">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select size='small'
        v-model.stop="form.status" placeholder="请选择">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="归属">
        <el-select size='small'
        v-model="form.belong" placeholder="请选择">
          <el-option
            v-for="item in belongOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="合同期限">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="footerBtn">
      <el-button type="primary" plain size='small' @click='cancelPop'>取消</el-button>
      <el-button type="primary" size='small' @click='cancelPop'>确定</el-button>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
export default defineComponent({
  name:"caseProjectBoxForm",
  props:{
    caseProjectBoxForm:{
      type:String,
      default:'case'
    }
  },
  mounted(){
    if(this.caseProjectBoxForm === 'project'){
      // 项目的选项类型
      this.typeOptions = [{
        value:1,
        label:"非诉专项"
      }]
    }else{
      // 默认为案件
      this.typeOptions = [{
        value:1,
        label:"刑事辩护"
      }]
    }
  },
  data(){
    return {
      form: {
        name: '',
        order:'',
        person:'',
        type:'',
        status:'',
        belong:'',
        date:''
      },
      typeOptions:[{
        value:1,
        label:"刑事辩护"
      }],
      statusOptions:[{
        value:1,
        label:"进行中"
      }],
      belongOptions:[{
        value:1,
        label:"个人"
      }]
    }
  },
  methods:{
    cancelPop(){
      this.$emit('closePop',false)
    }
  }
})
</script>

<style lang="scss" scoped>
.caseProjectBoxForm{
  .footerBtn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ::v-deep{
    .el-select,.el-range-editor.el-input__inner{
      width:100%
    }
  }
}

</style>