<template>
  <div class="caseProjectBox">
    <div class="topSerch">
      <div class="top">
        <div class="navBar">
          <el-select class='myAutocomplete'
            size='small'
            clearable
            filterable
            remote
            v-model="serchValue"
            prefix-icon='iconfont iconfangdajing'
            :remote-method="remoteMethod"
            :loading="loading"
            @change='changeSerchValue'
            :placeholder="listType=='project'?'项目名称 / 项目编号':'案件名称 / 案件编号'"
          >
          <el-option
            v-for="item in queryOptions"
            :key="item.caseId"
            :label="item.caseName"
            :value="item.caseId">
          </el-option>
          </el-select>
          <div class="selectBox"
          v-for='topFormItem in myTopForm'
          :key='topFormItem.name'>
            <div class="selectText">{{topFormItem.name}}<span></span></div>
            <el-select class='mySelect'
            v-model="topFormItem.selectVal" size='small'
            @change="myTopFormSelectChange($event,topFormItem.name)"
            >
              <el-option
                v-for="item in topFormItem.options"
                :key="item.key"
                :label="item.value"
                :value="item.key">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="moreFilter">
          <el-popover
            placement="bottom"
            :width="450"
            trigger='manual'
            v-model:visible="moreFilter">
            <template #reference>
              <div class="moreBtn" @click='moreFilterFn'>
                <div class="iconfont iconshaixuan"></div>
                <div class="text">更多筛选</div>
              </div>
            </template>
            <div style='margin-top:20px'>
              <caseProjectBoxForm 
              caseProjectBoxForm='project'
              @closePop='closePop'
              ></caseProjectBoxForm>
            </div>
          </el-popover>
        </div>
      </div>
      <div class="bottom">
        <el-button type="primary" plain size='small'>导出{{listType=='project'?'项目':'案件'}}</el-button>
        <el-button type="primary" size='small' @click='createCaseProject'>新建{{listType=='project'?'项目':'案件'}}</el-button>
      </div>
    </div>
    <div class="centerList" v-loading='caseListLoading'>
      <div class="centerListUl">
        <div class="listItemLi" 
        v-for='item in listData.data' :key='item.id'>
          <div class="part first">
            <div class="dateTime">
              <div>{{item.date}}</div>
              <div>{{item.order}}</div>
            </div>
            <div class="title" @click='routerPush("details",item.id)'>{{item.title}}</div>
          </div>
          <div class="part classType">{{item.classType}}</div>
          <div class="part lawyer">主办<span>|</span>{{item.lawyer}}</div>
          <div class="part">{{item.status}}</div>
          <div class="iconBox">
            <myPopover >
              <template v-slot:toggle>
                <div class="iconfont icongengduo"></div>
              </template>
              <div class="list">
                <div @click='routerPush("details",item.id)'>查看</div>
                <!-- <div @click='routerPush("copy")'>复制</div> -->
                <div @click='routerPush("isOver",item.id)'>{{listType==='project'?'结束':'结案'}}</div>
                <div @click='routerPush("create",item.id)'>{{listType==='project'?'立项':'立案'}}</div>
                <div @click='routerPush("delet",item.id)'>删除</div>
              </div>
            </myPopover>
          </div>
        </div>
      </div>
    </div>
    <div class="footerPagenation"></div>
  </div>
</template>

<script>
import {defineComponent,ref,onMounted, reactive,watch,watchEffect} from 'vue';
import caseProjectBoxForm from '@/components/caseProjectBoxForm.vue';
import myPopover from '@/components/myPopover.vue';
export default defineComponent({
  name:"caseProjectList",
  props:{
    caseListLoading:{
      type:Boolean,
      default:false
    },
    topForm:{
      type:Array,
      default:function(){
        return []
      }
    },
    listData:{
      type:Object,
      require:true,
      default:function(){
        return {
          data:[]
        }
      }
    },
    // 案件为case，项目为project
    listType:{
      type:String,
      require:true
    },
    queryList:{
      type:Array,
      require:true,
      default:function(){
        return []
      }
    }
  },
  watch:{
    topForm:{
      handler(newV,oldV){
        this.myTopForm = newV
      },
    }
  },
  emits:['createCaseProject','routerPush','query','upDate','myTopFormSelectChange'],
  setup(props,context) {
    // 新建案件/项目
    const createCaseProject = ()=>{
      context.emit('createCaseProject')
    };
    // 案件列表筛选
    // props
    let queryOptions = ref([]);
    let loading = ref(false);
    const remoteMethod = (query) => {
      context.emit('query',query)
    };
    watch(()=>props.queryList,(newV,oldV)=>{
      queryOptions.value = [...newV];
    })
    return {
      state: ref(''),
      remoteMethod,
      loading,
      createCaseProject,
      queryOptions
    };
  },
  data(){
    return {
      myTopForm:[],
      serchValue:'',
      options: [{
        value: 0,
        label: '全部'
      },{
        value: 1,
        label: '进行中'
      },{
        value: 2,
        label: '最长的名字字段在场'
      }],
      moreFilter:false,
    }
  },
  components:{
    caseProjectBoxForm,
    myPopover
  },
  methods:{
    myTopFormSelectChange(event,name){
      this.$emit('myTopFormSelectChange',{
        name,
        key:event
      })
    },
    changeSerchValue(v){
      this.$emit('upDate',v)
    },
    moreFilterFn(){
      this.moreFilter = !this.moreFilter
    },
    routerPush(type,id){
      let path;
      path = type;
      switch(type){
        case 'details' :
          this.listType == 'case'? path='/case/main':path='/project/main'
          this.$emit('routerPush',{path,id})
          break;
        case 'copy' :
          this.$emit('routerPush',{path,id})
          break;
        case 'isOver' :
          this.$emit('routerPush',{path,id})
          break;
        case 'create' :
          this.$emit('routerPush',{path,id})
          break;
        case 'delet' :
          this.$emit('routerPush',{path,id})
          break;
      }
    },
    closePop(val){
      this.moreFilter = val
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.caseProjectBox{
  @include container;
  background:#fff;
  border-radius:20px;
  .topSerch{
    .top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:20px 30px;
      border-bottom:1px solid #eee;
      margin-bottom:20px;
      .navBar{
        display: flex;
        align-items: center;
        .selectBox{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #DCDFE6;
          // height:32px;
          box-sizing: border-box;
          border-radius: 20px;
          width:180px;
          margin-left:20px;
          overflow:hidden;
          .selectText{
            font-size: 13px;
            color:#606266;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height:30px;
            width:80px;
            position: relative;
            span{
              position: absolute;
              top:50%;
              right: 0;
              transform: translateY(-50%);
              border-right:1px solid #DCDFE6;
              height:80%;
              display: block;
            }
          }
        }
        ::v-deep{
          .myAutocomplete{
            .el-input__inner{
              border-radius:20px;
            }
            .el-input__prefix{
              left: 8px;
            }
          }
          .mySelect{
            .el-input__inner{
              border:none;
              box-sizing: border-box;
              padding-left:10px
            }
          }
        }
        
      }
      .moreFilter{
        .moreBtn{
          display: flex;
          align-items: center;
          &:hover{
            cursor: pointer;
            .text,.iconfont{
              color:$main-color
            }
          }
          .text{
            font-size: 13px;
            color:#606266;
          }
          .iconfont{
            font-size: 20px;
            color:#606266;
            margin-right: 5px;
          }
        }
      }
    }
    .bottom{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding:20px 30px;
      padding-top: 0;
      border-bottom:1px solid #eee;
    }
    
  }
  .centerList{
    .centerListUl{
      padding:0 30px;
      .listItemLi{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom:1px solid #eee;
        padding:20px;
        &:hover{
          cursor: pointer;
          background:#fafafa
        }
        .first{
          .dateTime{
            font-size: 12px;
            color:#666;
            margin-bottom:15px;
            display:flex;
            align-items: center;
            justify-content: space-between;
          }
          .title{
            width:250px;
            @include ellipsis;
            &:hover{
              color:$main-color
            }
          }
        }
        .classType{
          width:100px;
        }
        .lawyer{
          width:100px;
        }
        .part{
          font-size: 14px;
          color:#555;
          span{
            margin:0 8px;
          }
        }
        .iconBox{
          .iconfont{
            &:hover{
              color:$main-color
            }
          }
          .list{
            width:40px;
            padding:10px 20px;
            div{
              font-size: 14px;
              color:#555;
              width:100%;
              text-align: center;
              padding:10px 0;
              &:hover{
                color:$main-color
              }
            }
          }
        }
      }
    }
  }
}
</style>